import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/user/get`, {
            apikey: this.state.customConfig.token,
            perPage: payload.perPage,
            currentPage: payload.currentPage,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/user/get`, {
            apikey: this.state.customConfig.token,
            perPage: payload.perPage,
            currentPage: payload.currentPage,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/role/dropdown`, {
            apikey: this.state.customConfig.token,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArea() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/area/dropdown`, {
            apikey: this.state.customConfig.token,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
