<template>
  <div id="dashboard-patrol">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              v-if="$can('create', 'monitoring_user')"
              variant="primary"
              :to="{ name: 'settings-users-create'}"
              class="mr-2"
            >
              <span class="text-nowrap">Create</span>
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        :items="dataUsers"
        responsive
        :fields="tableColumns"
        :filter="searchQuery"
        :sort-by.sync="sortBy"
        striped
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(actions)="data">
          <b-button
            v-if="$can('edit', 'monitoring_user')"
            variant="primary"
            :to="{ name: 'settings-users-edit', params: { id: data.item.id } }"
            class="mr-2 mb-1"
          >
            <span class="text-nowrap">Edit</span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onUnmounted, ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '@/views/apps/settings/users/userStoreModule'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,

    vSelect,
  },
  methods: {
  },
  setup() {
    const AREA_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(AREA_APP_STORE_MODULE_NAME)) store.registerModule(AREA_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AREA_APP_STORE_MODULE_NAME)) store.unregisterModule(AREA_APP_STORE_MODULE_NAME)
    })

    // Table Handlers
    const tableColumns = [
      { key: 'area_name', label: 'Site', sortable: true },
      { key: 'name', sortable: true },
      { key: 'nik', sortable: true },
      { key: 'email', sortable: true },
      { key: 'phone_number', label: 'No Handphone', sortable: true },
      { key: 'role_name', label: 'role', sortable: true },
      { key: 'modified_by', label: 'last modified by', sortable: true },
      { key: 'modified_at', label: 'last modified date', sortable: true },
      { 
        key: 'status',  
        sortable: true,
        formatter: (val) => val == 1 ? 'Enabled' : 'Disabled',
        filterByFormatted: true,
      },
      { key: 'actions' },
    ]

    // Use toast
    const toast = useToast()

    const refUserListTable = ref(null)
    const dataUsers = ref(null)

    const perPage = ref(10)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalUsers.value,
      }
    })

    const refetchData = () => {
      fetchUsers()
      refUserListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    const fetchUsers = (ctx, callback) => {
      store
        .dispatch('app-user/fetchUsers', {
          // q: searchQuery.value,
          perPage: perPage.value,
          currentPage: currentPage.value,
          // sortBy: sortBy.value,
          // sortDesc: isSortDirDesc.value,
          // status: statusFilter.value,
        })
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          // const data = response.data.data.list
          // callback(data)
          dataUsers.value = response.data.data.list
          totalUsers.value = response.data.data.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching area list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchUsers()

    return {
      dataUsers,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      totalUsers,

      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
